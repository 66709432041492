/* eslint-disable */
import _wallets_walletconnect from '../../../../public/images/icons/wallets/walletconnect.svg?url'
import _wallets_walletconnectSource from '!!raw-loader!../../../../public/images/icons/wallets/walletconnect.svg'
import _wallets_metamask from '../../../../public/images/icons/wallets/metamask.svg?url'
import _wallets_metamaskSource from '!!raw-loader!../../../../public/images/icons/wallets/metamask.svg'
import _tokens_xdai from '../../../../public/images/icons/tokens/xdai.svg?url'
import _tokens_xdaiSource from '!!raw-loader!../../../../public/images/icons/tokens/xdai.svg'
import _tokens_wxdai from '../../../../public/images/icons/tokens/wxdai.svg?url'
import _tokens_wxdaiSource from '!!raw-loader!../../../../public/images/icons/tokens/wxdai.svg'
import _tokens_weth from '../../../../public/images/icons/tokens/weth.svg?url'
import _tokens_wethSource from '!!raw-loader!../../../../public/images/icons/tokens/weth.svg'
import _tokens_wchz from '../../../../public/images/icons/tokens/wchz.svg?url'
import _tokens_wchzSource from '!!raw-loader!../../../../public/images/icons/tokens/wchz.svg'
import _tokens_usdt from '../../../../public/images/icons/tokens/usdt.svg?url'
import _tokens_usdtSource from '!!raw-loader!../../../../public/images/icons/tokens/usdt.svg'
import _tokens_usdc from '../../../../public/images/icons/tokens/usdc.svg?url'
import _tokens_usdcSource from '!!raw-loader!../../../../public/images/icons/tokens/usdc.svg'
import _tokens_pol from '../../../../public/images/icons/tokens/pol.svg?url'
import _tokens_polSource from '!!raw-loader!../../../../public/images/icons/tokens/pol.svg'
import _tokens_eth from '../../../../public/images/icons/tokens/eth.svg?url'
import _tokens_ethSource from '!!raw-loader!../../../../public/images/icons/tokens/eth.svg'
import _tokens_chz from '../../../../public/images/icons/tokens/chz.svg?url'
import _tokens_chzSource from '!!raw-loader!../../../../public/images/icons/tokens/chz.svg'
import _tokens_bnb from '../../../../public/images/icons/tokens/bnb.svg?url'
import _tokens_bnbSource from '!!raw-loader!../../../../public/images/icons/tokens/bnb.svg'
import _tokens_azuro from '../../../../public/images/icons/tokens/azuro.svg?url'
import _tokens_azuroSource from '!!raw-loader!../../../../public/images/icons/tokens/azuro.svg'
import _tokens_azuro_staked from '../../../../public/images/icons/tokens/azuro-staked.svg?url'
import _tokens_azuro_stakedSource from '!!raw-loader!../../../../public/images/icons/tokens/azuro-staked.svg'
import _networks_solana from '../../../../public/images/icons/networks/solana.svg?url'
import _networks_solanaSource from '!!raw-loader!../../../../public/images/icons/networks/solana.svg'
import _networks_polygon from '../../../../public/images/icons/networks/polygon.svg?url'
import _networks_polygonSource from '!!raw-loader!../../../../public/images/icons/networks/polygon.svg'
import _networks_linea from '../../../../public/images/icons/networks/linea.svg?url'
import _networks_lineaSource from '!!raw-loader!../../../../public/images/icons/networks/linea.svg'
import _networks_gnosis from '../../../../public/images/icons/networks/gnosis.svg?url'
import _networks_gnosisSource from '!!raw-loader!../../../../public/images/icons/networks/gnosis.svg'
import _networks_eth from '../../../../public/images/icons/networks/eth.svg?url'
import _networks_ethSource from '!!raw-loader!../../../../public/images/icons/networks/eth.svg'
import _networks_chiliz from '../../../../public/images/icons/networks/chiliz.svg?url'
import _networks_chilizSource from '!!raw-loader!../../../../public/images/icons/networks/chiliz.svg'
import _networks_bnb from '../../../../public/images/icons/networks/bnb.svg?url'
import _networks_bnbSource from '!!raw-loader!../../../../public/images/icons/networks/bnb.svg'
import _networks_arbitrum from '../../../../public/images/icons/networks/arbitrum.svg?url'
import _networks_arbitrumSource from '!!raw-loader!../../../../public/images/icons/networks/arbitrum.svg'
import _misc_mask_pair_round_right from '../../../../public/images/icons/misc/mask-pair-round-right.svg?url'
import _misc_mask_pair_round_rightSource from '!!raw-loader!../../../../public/images/icons/misc/mask-pair-round-right.svg'
import _misc_mask_pair_round_left from '../../../../public/images/icons/misc/mask-pair-round-left.svg?url'
import _misc_mask_pair_round_leftSource from '!!raw-loader!../../../../public/images/icons/misc/mask-pair-round-left.svg'
import _misc_laurel from '../../../../public/images/icons/misc/laurel.svg?url'
import _misc_laurelSource from '!!raw-loader!../../../../public/images/icons/misc/laurel.svg'
import _interface_withdraw from '../../../../public/images/icons/interface/withdraw.svg?url'
import _interface_withdrawSource from '!!raw-loader!../../../../public/images/icons/interface/withdraw.svg'
import _interface_wallet from '../../../../public/images/icons/interface/wallet.svg?url'
import _interface_walletSource from '!!raw-loader!../../../../public/images/icons/interface/wallet.svg'
import _interface_vesting from '../../../../public/images/icons/interface/vesting.svg?url'
import _interface_vestingSource from '!!raw-loader!../../../../public/images/icons/interface/vesting.svg'
import _interface_user_avatar from '../../../../public/images/icons/interface/user-avatar.svg?url'
import _interface_user_avatarSource from '!!raw-loader!../../../../public/images/icons/interface/user-avatar.svg'
import _interface_twitter from '../../../../public/images/icons/interface/twitter.svg?url'
import _interface_twitterSource from '!!raw-loader!../../../../public/images/icons/interface/twitter.svg'
import _interface_transaction_history from '../../../../public/images/icons/interface/transaction-history.svg?url'
import _interface_transaction_historySource from '!!raw-loader!../../../../public/images/icons/interface/transaction-history.svg'
import _interface_stopwatch from '../../../../public/images/icons/interface/stopwatch.svg?url'
import _interface_stopwatchSource from '!!raw-loader!../../../../public/images/icons/interface/stopwatch.svg'
import _interface_staking from '../../../../public/images/icons/interface/staking.svg?url'
import _interface_stakingSource from '!!raw-loader!../../../../public/images/icons/interface/staking.svg'
import _interface_spinner from '../../../../public/images/icons/interface/spinner.svg?url'
import _interface_spinnerSource from '!!raw-loader!../../../../public/images/icons/interface/spinner.svg'
import _interface_snapshot from '../../../../public/images/icons/interface/snapshot.svg?url'
import _interface_snapshotSource from '!!raw-loader!../../../../public/images/icons/interface/snapshot.svg'
import _interface_reset from '../../../../public/images/icons/interface/reset.svg?url'
import _interface_resetSource from '!!raw-loader!../../../../public/images/icons/interface/reset.svg'
import _interface_question_circle from '../../../../public/images/icons/interface/question-circle.svg?url'
import _interface_question_circleSource from '!!raw-loader!../../../../public/images/icons/interface/question-circle.svg'
import _interface_preloader from '../../../../public/images/icons/interface/preloader.svg?url'
import _interface_preloaderSource from '!!raw-loader!../../../../public/images/icons/interface/preloader.svg'
import _interface_plus from '../../../../public/images/icons/interface/plus.svg?url'
import _interface_plusSource from '!!raw-loader!../../../../public/images/icons/interface/plus.svg'
import _interface_notification from '../../../../public/images/icons/interface/notification.svg?url'
import _interface_notificationSource from '!!raw-loader!../../../../public/images/icons/interface/notification.svg'
import _interface_my_deposits from '../../../../public/images/icons/interface/my-deposits.svg?url'
import _interface_my_depositsSource from '!!raw-loader!../../../../public/images/icons/interface/my-deposits.svg'
import _interface_medium from '../../../../public/images/icons/interface/medium.svg?url'
import _interface_mediumSource from '!!raw-loader!../../../../public/images/icons/interface/medium.svg'
import _interface_log_out from '../../../../public/images/icons/interface/log-out.svg?url'
import _interface_log_outSource from '!!raw-loader!../../../../public/images/icons/interface/log-out.svg'
import _interface_lock from '../../../../public/images/icons/interface/lock.svg?url'
import _interface_lockSource from '!!raw-loader!../../../../public/images/icons/interface/lock.svg'
import _interface_liquidity_pools from '../../../../public/images/icons/interface/liquidity-pools.svg?url'
import _interface_liquidity_poolsSource from '!!raw-loader!../../../../public/images/icons/interface/liquidity-pools.svg'
import _interface_info_circle from '../../../../public/images/icons/interface/info-circle.svg?url'
import _interface_info_circleSource from '!!raw-loader!../../../../public/images/icons/interface/info-circle.svg'
import _interface_governance from '../../../../public/images/icons/interface/governance.svg?url'
import _interface_governanceSource from '!!raw-loader!../../../../public/images/icons/interface/governance.svg'
import _interface_github from '../../../../public/images/icons/interface/github.svg?url'
import _interface_githubSource from '!!raw-loader!../../../../public/images/icons/interface/github.svg'
import _interface_frontend_rewards from '../../../../public/images/icons/interface/frontend-rewards.svg?url'
import _interface_frontend_rewardsSource from '!!raw-loader!../../../../public/images/icons/interface/frontend-rewards.svg'
import _interface_filters from '../../../../public/images/icons/interface/filters.svg?url'
import _interface_filtersSource from '!!raw-loader!../../../../public/images/icons/interface/filters.svg'
import _interface_extrernal_link from '../../../../public/images/icons/interface/extrernal-link.svg?url'
import _interface_extrernal_linkSource from '!!raw-loader!../../../../public/images/icons/interface/extrernal-link.svg'
import _interface_extrernal_link_40 from '../../../../public/images/icons/interface/extrernal-link-40.svg?url'
import _interface_extrernal_link_40Source from '!!raw-loader!../../../../public/images/icons/interface/extrernal-link-40.svg'
import _interface_enhance from '../../../../public/images/icons/interface/enhance.svg?url'
import _interface_enhanceSource from '!!raw-loader!../../../../public/images/icons/interface/enhance.svg'
import _interface_dune_analytics from '../../../../public/images/icons/interface/dune-analytics.svg?url'
import _interface_dune_analyticsSource from '!!raw-loader!../../../../public/images/icons/interface/dune-analytics.svg'
import _interface_download from '../../../../public/images/icons/interface/download.svg?url'
import _interface_downloadSource from '!!raw-loader!../../../../public/images/icons/interface/download.svg'
import _interface_dots_horizontal from '../../../../public/images/icons/interface/dots-horizontal.svg?url'
import _interface_dots_horizontalSource from '!!raw-loader!../../../../public/images/icons/interface/dots-horizontal.svg'
import _interface_discord from '../../../../public/images/icons/interface/discord.svg?url'
import _interface_discordSource from '!!raw-loader!../../../../public/images/icons/interface/discord.svg'
import _interface_dex_liquidity from '../../../../public/images/icons/interface/dex-liquidity.svg?url'
import _interface_dex_liquiditySource from '!!raw-loader!../../../../public/images/icons/interface/dex-liquidity.svg'
import _interface_detailed from '../../../../public/images/icons/interface/detailed.svg?url'
import _interface_detailedSource from '!!raw-loader!../../../../public/images/icons/interface/detailed.svg'
import _interface_deposit from '../../../../public/images/icons/interface/deposit.svg?url'
import _interface_depositSource from '!!raw-loader!../../../../public/images/icons/interface/deposit.svg'
import _interface_copy from '../../../../public/images/icons/interface/copy.svg?url'
import _interface_copySource from '!!raw-loader!../../../../public/images/icons/interface/copy.svg'
import _interface_close from '../../../../public/images/icons/interface/close.svg?url'
import _interface_closeSource from '!!raw-loader!../../../../public/images/icons/interface/close.svg'
import _interface_clear from '../../../../public/images/icons/interface/clear.svg?url'
import _interface_clearSource from '!!raw-loader!../../../../public/images/icons/interface/clear.svg'
import _interface_chevron_right from '../../../../public/images/icons/interface/chevron-right.svg?url'
import _interface_chevron_rightSource from '!!raw-loader!../../../../public/images/icons/interface/chevron-right.svg'
import _interface_chevron_down from '../../../../public/images/icons/interface/chevron-down.svg?url'
import _interface_chevron_downSource from '!!raw-loader!../../../../public/images/icons/interface/chevron-down.svg'
import _interface_check from '../../../../public/images/icons/interface/check.svg?url'
import _interface_checkSource from '!!raw-loader!../../../../public/images/icons/interface/check.svg'
import _interface_check_circle from '../../../../public/images/icons/interface/check-circle.svg?url'
import _interface_check_circleSource from '!!raw-loader!../../../../public/images/icons/interface/check-circle.svg'
import _interface_check_border from '../../../../public/images/icons/interface/check-border.svg?url'
import _interface_check_borderSource from '!!raw-loader!../../../../public/images/icons/interface/check-border.svg'
import _interface_caret_up from '../../../../public/images/icons/interface/caret-up.svg?url'
import _interface_caret_upSource from '!!raw-loader!../../../../public/images/icons/interface/caret-up.svg'
import _interface_caret_down from '../../../../public/images/icons/interface/caret-down.svg?url'
import _interface_caret_downSource from '!!raw-loader!../../../../public/images/icons/interface/caret-down.svg'
import _interface_calendar_date from '../../../../public/images/icons/interface/calendar-date.svg?url'
import _interface_calendar_dateSource from '!!raw-loader!../../../../public/images/icons/interface/calendar-date.svg'
import _interface_azuro_score from '../../../../public/images/icons/interface/azuro-score.svg?url'
import _interface_azuro_scoreSource from '!!raw-loader!../../../../public/images/icons/interface/azuro-score.svg'
import _interface_arrow_tooltip from '../../../../public/images/icons/interface/arrow_tooltip.svg?url'
import _interface_arrow_tooltipSource from '!!raw-loader!../../../../public/images/icons/interface/arrow_tooltip.svg'
import _interface_arrow_right from '../../../../public/images/icons/interface/arrow-right.svg?url'
import _interface_arrow_rightSource from '!!raw-loader!../../../../public/images/icons/interface/arrow-right.svg'
import _interface_alert_triangle from '../../../../public/images/icons/interface/alert-triangle.svg?url'
import _interface_alert_triangleSource from '!!raw-loader!../../../../public/images/icons/interface/alert-triangle.svg'
import _interface_alert_circle from '../../../../public/images/icons/interface/alert-circle.svg?url'
import _interface_alert_circleSource from '!!raw-loader!../../../../public/images/icons/interface/alert-circle.svg'
import _interface_airdrop from '../../../../public/images/icons/interface/airdrop.svg?url'
import _interface_airdropSource from '!!raw-loader!../../../../public/images/icons/interface/airdrop.svg'

const isServer = typeof window === 'undefined'

const icons = {
  'wallets/walletconnect': { src: _wallets_walletconnect.src, source: isServer && _wallets_walletconnectSource, width: _wallets_walletconnect.width, height: _wallets_walletconnect.height, aspect: _wallets_walletconnect.width/_wallets_walletconnect.height }, 
  'wallets/metamask': { src: _wallets_metamask.src, source: isServer && _wallets_metamaskSource, width: _wallets_metamask.width, height: _wallets_metamask.height, aspect: _wallets_metamask.width/_wallets_metamask.height }, 
  'tokens/xdai': { src: _tokens_xdai.src, source: isServer && _tokens_xdaiSource, width: _tokens_xdai.width, height: _tokens_xdai.height, aspect: _tokens_xdai.width/_tokens_xdai.height }, 
  'tokens/wxdai': { src: _tokens_wxdai.src, source: isServer && _tokens_wxdaiSource, width: _tokens_wxdai.width, height: _tokens_wxdai.height, aspect: _tokens_wxdai.width/_tokens_wxdai.height }, 
  'tokens/weth': { src: _tokens_weth.src, source: isServer && _tokens_wethSource, width: _tokens_weth.width, height: _tokens_weth.height, aspect: _tokens_weth.width/_tokens_weth.height }, 
  'tokens/wchz': { src: _tokens_wchz.src, source: isServer && _tokens_wchzSource, width: _tokens_wchz.width, height: _tokens_wchz.height, aspect: _tokens_wchz.width/_tokens_wchz.height }, 
  'tokens/usdt': { src: _tokens_usdt.src, source: isServer && _tokens_usdtSource, width: _tokens_usdt.width, height: _tokens_usdt.height, aspect: _tokens_usdt.width/_tokens_usdt.height }, 
  'tokens/usdc': { src: _tokens_usdc.src, source: isServer && _tokens_usdcSource, width: _tokens_usdc.width, height: _tokens_usdc.height, aspect: _tokens_usdc.width/_tokens_usdc.height }, 
  'tokens/pol': { src: _tokens_pol.src, source: isServer && _tokens_polSource, width: _tokens_pol.width, height: _tokens_pol.height, aspect: _tokens_pol.width/_tokens_pol.height }, 
  'tokens/eth': { src: _tokens_eth.src, source: isServer && _tokens_ethSource, width: _tokens_eth.width, height: _tokens_eth.height, aspect: _tokens_eth.width/_tokens_eth.height }, 
  'tokens/chz': { src: _tokens_chz.src, source: isServer && _tokens_chzSource, width: _tokens_chz.width, height: _tokens_chz.height, aspect: _tokens_chz.width/_tokens_chz.height }, 
  'tokens/bnb': { src: _tokens_bnb.src, source: isServer && _tokens_bnbSource, width: _tokens_bnb.width, height: _tokens_bnb.height, aspect: _tokens_bnb.width/_tokens_bnb.height }, 
  'tokens/azuro': { src: _tokens_azuro.src, source: isServer && _tokens_azuroSource, width: _tokens_azuro.width, height: _tokens_azuro.height, aspect: _tokens_azuro.width/_tokens_azuro.height }, 
  'tokens/azuro-staked': { src: _tokens_azuro_staked.src, source: isServer && _tokens_azuro_stakedSource, width: _tokens_azuro_staked.width, height: _tokens_azuro_staked.height, aspect: _tokens_azuro_staked.width/_tokens_azuro_staked.height }, 
  'networks/solana': { src: _networks_solana.src, source: isServer && _networks_solanaSource, width: _networks_solana.width, height: _networks_solana.height, aspect: _networks_solana.width/_networks_solana.height }, 
  'networks/polygon': { src: _networks_polygon.src, source: isServer && _networks_polygonSource, width: _networks_polygon.width, height: _networks_polygon.height, aspect: _networks_polygon.width/_networks_polygon.height }, 
  'networks/linea': { src: _networks_linea.src, source: isServer && _networks_lineaSource, width: _networks_linea.width, height: _networks_linea.height, aspect: _networks_linea.width/_networks_linea.height }, 
  'networks/gnosis': { src: _networks_gnosis.src, source: isServer && _networks_gnosisSource, width: _networks_gnosis.width, height: _networks_gnosis.height, aspect: _networks_gnosis.width/_networks_gnosis.height }, 
  'networks/eth': { src: _networks_eth.src, source: isServer && _networks_ethSource, width: _networks_eth.width, height: _networks_eth.height, aspect: _networks_eth.width/_networks_eth.height }, 
  'networks/chiliz': { src: _networks_chiliz.src, source: isServer && _networks_chilizSource, width: _networks_chiliz.width, height: _networks_chiliz.height, aspect: _networks_chiliz.width/_networks_chiliz.height }, 
  'networks/bnb': { src: _networks_bnb.src, source: isServer && _networks_bnbSource, width: _networks_bnb.width, height: _networks_bnb.height, aspect: _networks_bnb.width/_networks_bnb.height }, 
  'networks/arbitrum': { src: _networks_arbitrum.src, source: isServer && _networks_arbitrumSource, width: _networks_arbitrum.width, height: _networks_arbitrum.height, aspect: _networks_arbitrum.width/_networks_arbitrum.height }, 
  'misc/mask-pair-round-right': { src: _misc_mask_pair_round_right.src, source: isServer && _misc_mask_pair_round_rightSource, width: _misc_mask_pair_round_right.width, height: _misc_mask_pair_round_right.height, aspect: _misc_mask_pair_round_right.width/_misc_mask_pair_round_right.height }, 
  'misc/mask-pair-round-left': { src: _misc_mask_pair_round_left.src, source: isServer && _misc_mask_pair_round_leftSource, width: _misc_mask_pair_round_left.width, height: _misc_mask_pair_round_left.height, aspect: _misc_mask_pair_round_left.width/_misc_mask_pair_round_left.height }, 
  'misc/laurel': { src: _misc_laurel.src, source: isServer && _misc_laurelSource, width: _misc_laurel.width, height: _misc_laurel.height, aspect: _misc_laurel.width/_misc_laurel.height }, 
  'interface/withdraw': { src: _interface_withdraw.src, source: isServer && _interface_withdrawSource, width: _interface_withdraw.width, height: _interface_withdraw.height, aspect: _interface_withdraw.width/_interface_withdraw.height }, 
  'interface/wallet': { src: _interface_wallet.src, source: isServer && _interface_walletSource, width: _interface_wallet.width, height: _interface_wallet.height, aspect: _interface_wallet.width/_interface_wallet.height }, 
  'interface/vesting': { src: _interface_vesting.src, source: isServer && _interface_vestingSource, width: _interface_vesting.width, height: _interface_vesting.height, aspect: _interface_vesting.width/_interface_vesting.height }, 
  'interface/user-avatar': { src: _interface_user_avatar.src, source: isServer && _interface_user_avatarSource, width: _interface_user_avatar.width, height: _interface_user_avatar.height, aspect: _interface_user_avatar.width/_interface_user_avatar.height }, 
  'interface/twitter': { src: _interface_twitter.src, source: isServer && _interface_twitterSource, width: _interface_twitter.width, height: _interface_twitter.height, aspect: _interface_twitter.width/_interface_twitter.height }, 
  'interface/transaction-history': { src: _interface_transaction_history.src, source: isServer && _interface_transaction_historySource, width: _interface_transaction_history.width, height: _interface_transaction_history.height, aspect: _interface_transaction_history.width/_interface_transaction_history.height }, 
  'interface/stopwatch': { src: _interface_stopwatch.src, source: isServer && _interface_stopwatchSource, width: _interface_stopwatch.width, height: _interface_stopwatch.height, aspect: _interface_stopwatch.width/_interface_stopwatch.height }, 
  'interface/staking': { src: _interface_staking.src, source: isServer && _interface_stakingSource, width: _interface_staking.width, height: _interface_staking.height, aspect: _interface_staking.width/_interface_staking.height }, 
  'interface/spinner': { src: _interface_spinner.src, source: isServer && _interface_spinnerSource, width: _interface_spinner.width, height: _interface_spinner.height, aspect: _interface_spinner.width/_interface_spinner.height }, 
  'interface/snapshot': { src: _interface_snapshot.src, source: isServer && _interface_snapshotSource, width: _interface_snapshot.width, height: _interface_snapshot.height, aspect: _interface_snapshot.width/_interface_snapshot.height }, 
  'interface/reset': { src: _interface_reset.src, source: isServer && _interface_resetSource, width: _interface_reset.width, height: _interface_reset.height, aspect: _interface_reset.width/_interface_reset.height }, 
  'interface/question-circle': { src: _interface_question_circle.src, source: isServer && _interface_question_circleSource, width: _interface_question_circle.width, height: _interface_question_circle.height, aspect: _interface_question_circle.width/_interface_question_circle.height }, 
  'interface/preloader': { src: _interface_preloader.src, source: isServer && _interface_preloaderSource, width: _interface_preloader.width, height: _interface_preloader.height, aspect: _interface_preloader.width/_interface_preloader.height }, 
  'interface/plus': { src: _interface_plus.src, source: isServer && _interface_plusSource, width: _interface_plus.width, height: _interface_plus.height, aspect: _interface_plus.width/_interface_plus.height }, 
  'interface/notification': { src: _interface_notification.src, source: isServer && _interface_notificationSource, width: _interface_notification.width, height: _interface_notification.height, aspect: _interface_notification.width/_interface_notification.height }, 
  'interface/my-deposits': { src: _interface_my_deposits.src, source: isServer && _interface_my_depositsSource, width: _interface_my_deposits.width, height: _interface_my_deposits.height, aspect: _interface_my_deposits.width/_interface_my_deposits.height }, 
  'interface/medium': { src: _interface_medium.src, source: isServer && _interface_mediumSource, width: _interface_medium.width, height: _interface_medium.height, aspect: _interface_medium.width/_interface_medium.height }, 
  'interface/log-out': { src: _interface_log_out.src, source: isServer && _interface_log_outSource, width: _interface_log_out.width, height: _interface_log_out.height, aspect: _interface_log_out.width/_interface_log_out.height }, 
  'interface/lock': { src: _interface_lock.src, source: isServer && _interface_lockSource, width: _interface_lock.width, height: _interface_lock.height, aspect: _interface_lock.width/_interface_lock.height }, 
  'interface/liquidity-pools': { src: _interface_liquidity_pools.src, source: isServer && _interface_liquidity_poolsSource, width: _interface_liquidity_pools.width, height: _interface_liquidity_pools.height, aspect: _interface_liquidity_pools.width/_interface_liquidity_pools.height }, 
  'interface/info-circle': { src: _interface_info_circle.src, source: isServer && _interface_info_circleSource, width: _interface_info_circle.width, height: _interface_info_circle.height, aspect: _interface_info_circle.width/_interface_info_circle.height }, 
  'interface/governance': { src: _interface_governance.src, source: isServer && _interface_governanceSource, width: _interface_governance.width, height: _interface_governance.height, aspect: _interface_governance.width/_interface_governance.height }, 
  'interface/github': { src: _interface_github.src, source: isServer && _interface_githubSource, width: _interface_github.width, height: _interface_github.height, aspect: _interface_github.width/_interface_github.height }, 
  'interface/frontend-rewards': { src: _interface_frontend_rewards.src, source: isServer && _interface_frontend_rewardsSource, width: _interface_frontend_rewards.width, height: _interface_frontend_rewards.height, aspect: _interface_frontend_rewards.width/_interface_frontend_rewards.height }, 
  'interface/filters': { src: _interface_filters.src, source: isServer && _interface_filtersSource, width: _interface_filters.width, height: _interface_filters.height, aspect: _interface_filters.width/_interface_filters.height }, 
  'interface/extrernal-link': { src: _interface_extrernal_link.src, source: isServer && _interface_extrernal_linkSource, width: _interface_extrernal_link.width, height: _interface_extrernal_link.height, aspect: _interface_extrernal_link.width/_interface_extrernal_link.height }, 
  'interface/extrernal-link-40': { src: _interface_extrernal_link_40.src, source: isServer && _interface_extrernal_link_40Source, width: _interface_extrernal_link_40.width, height: _interface_extrernal_link_40.height, aspect: _interface_extrernal_link_40.width/_interface_extrernal_link_40.height }, 
  'interface/enhance': { src: _interface_enhance.src, source: isServer && _interface_enhanceSource, width: _interface_enhance.width, height: _interface_enhance.height, aspect: _interface_enhance.width/_interface_enhance.height }, 
  'interface/dune-analytics': { src: _interface_dune_analytics.src, source: isServer && _interface_dune_analyticsSource, width: _interface_dune_analytics.width, height: _interface_dune_analytics.height, aspect: _interface_dune_analytics.width/_interface_dune_analytics.height }, 
  'interface/download': { src: _interface_download.src, source: isServer && _interface_downloadSource, width: _interface_download.width, height: _interface_download.height, aspect: _interface_download.width/_interface_download.height }, 
  'interface/dots-horizontal': { src: _interface_dots_horizontal.src, source: isServer && _interface_dots_horizontalSource, width: _interface_dots_horizontal.width, height: _interface_dots_horizontal.height, aspect: _interface_dots_horizontal.width/_interface_dots_horizontal.height }, 
  'interface/discord': { src: _interface_discord.src, source: isServer && _interface_discordSource, width: _interface_discord.width, height: _interface_discord.height, aspect: _interface_discord.width/_interface_discord.height }, 
  'interface/dex-liquidity': { src: _interface_dex_liquidity.src, source: isServer && _interface_dex_liquiditySource, width: _interface_dex_liquidity.width, height: _interface_dex_liquidity.height, aspect: _interface_dex_liquidity.width/_interface_dex_liquidity.height }, 
  'interface/detailed': { src: _interface_detailed.src, source: isServer && _interface_detailedSource, width: _interface_detailed.width, height: _interface_detailed.height, aspect: _interface_detailed.width/_interface_detailed.height }, 
  'interface/deposit': { src: _interface_deposit.src, source: isServer && _interface_depositSource, width: _interface_deposit.width, height: _interface_deposit.height, aspect: _interface_deposit.width/_interface_deposit.height }, 
  'interface/copy': { src: _interface_copy.src, source: isServer && _interface_copySource, width: _interface_copy.width, height: _interface_copy.height, aspect: _interface_copy.width/_interface_copy.height }, 
  'interface/close': { src: _interface_close.src, source: isServer && _interface_closeSource, width: _interface_close.width, height: _interface_close.height, aspect: _interface_close.width/_interface_close.height }, 
  'interface/clear': { src: _interface_clear.src, source: isServer && _interface_clearSource, width: _interface_clear.width, height: _interface_clear.height, aspect: _interface_clear.width/_interface_clear.height }, 
  'interface/chevron-right': { src: _interface_chevron_right.src, source: isServer && _interface_chevron_rightSource, width: _interface_chevron_right.width, height: _interface_chevron_right.height, aspect: _interface_chevron_right.width/_interface_chevron_right.height }, 
  'interface/chevron-down': { src: _interface_chevron_down.src, source: isServer && _interface_chevron_downSource, width: _interface_chevron_down.width, height: _interface_chevron_down.height, aspect: _interface_chevron_down.width/_interface_chevron_down.height }, 
  'interface/check': { src: _interface_check.src, source: isServer && _interface_checkSource, width: _interface_check.width, height: _interface_check.height, aspect: _interface_check.width/_interface_check.height }, 
  'interface/check-circle': { src: _interface_check_circle.src, source: isServer && _interface_check_circleSource, width: _interface_check_circle.width, height: _interface_check_circle.height, aspect: _interface_check_circle.width/_interface_check_circle.height }, 
  'interface/check-border': { src: _interface_check_border.src, source: isServer && _interface_check_borderSource, width: _interface_check_border.width, height: _interface_check_border.height, aspect: _interface_check_border.width/_interface_check_border.height }, 
  'interface/caret-up': { src: _interface_caret_up.src, source: isServer && _interface_caret_upSource, width: _interface_caret_up.width, height: _interface_caret_up.height, aspect: _interface_caret_up.width/_interface_caret_up.height }, 
  'interface/caret-down': { src: _interface_caret_down.src, source: isServer && _interface_caret_downSource, width: _interface_caret_down.width, height: _interface_caret_down.height, aspect: _interface_caret_down.width/_interface_caret_down.height }, 
  'interface/calendar-date': { src: _interface_calendar_date.src, source: isServer && _interface_calendar_dateSource, width: _interface_calendar_date.width, height: _interface_calendar_date.height, aspect: _interface_calendar_date.width/_interface_calendar_date.height }, 
  'interface/azuro-score': { src: _interface_azuro_score.src, source: isServer && _interface_azuro_scoreSource, width: _interface_azuro_score.width, height: _interface_azuro_score.height, aspect: _interface_azuro_score.width/_interface_azuro_score.height }, 
  'interface/arrow_tooltip': { src: _interface_arrow_tooltip.src, source: isServer && _interface_arrow_tooltipSource, width: _interface_arrow_tooltip.width, height: _interface_arrow_tooltip.height, aspect: _interface_arrow_tooltip.width/_interface_arrow_tooltip.height }, 
  'interface/arrow-right': { src: _interface_arrow_right.src, source: isServer && _interface_arrow_rightSource, width: _interface_arrow_right.width, height: _interface_arrow_right.height, aspect: _interface_arrow_right.width/_interface_arrow_right.height }, 
  'interface/alert-triangle': { src: _interface_alert_triangle.src, source: isServer && _interface_alert_triangleSource, width: _interface_alert_triangle.width, height: _interface_alert_triangle.height, aspect: _interface_alert_triangle.width/_interface_alert_triangle.height }, 
  'interface/alert-circle': { src: _interface_alert_circle.src, source: isServer && _interface_alert_circleSource, width: _interface_alert_circle.width, height: _interface_alert_circle.height, aspect: _interface_alert_circle.width/_interface_alert_circle.height }, 
  'interface/airdrop': { src: _interface_airdrop.src, source: isServer && _interface_airdropSource, width: _interface_airdrop.width, height: _interface_airdrop.height, aspect: _interface_airdrop.width/_interface_airdrop.height }
} as const

export type IconName = keyof typeof icons
export default icons