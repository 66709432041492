import { memo } from 'react'
import { svgRuntime } from './SvgProvider'


const containerId = 'svg-sprite'
const baseInnerHtml = `
<defs>
  <linearGradient id="gradient-sky" gradientUnits="userSpaceOnUse">
      <stop stop-color="#94D3F3" />
      <stop stop-color="#83D5FF" offset="100%" />
  </linearGradient>
  <linearGradient id="gradient-blue" gradientUnits="userSpaceOnUse">
      <stop stop-color="#83D5FF" />
      <stop stop-color="#64CAFF" offset="100%" />
  </linearGradient>
  <linearGradient id="gradient-ultramarine" gradientUnits="userSpaceOnUse">
      <stop stop-color="#64CAFF" />
      <stop stop-color="#05AAFF" offset="100%" />
  </linearGradient>
  <linearGradient id="gradient-bright" gradientUnits="userSpaceOnUse">
      <stop stop-color="#05AAFF" />
      <stop stop-color="#3499FE" offset="100%" />
  </linearGradient>
  <linearGradient id="gradient-brilliant" gradientUnits="userSpaceOnUse">
      <stop stop-color="#3499FE" />
      <stop stop-color="#3D67FF" offset="100%" />
  </linearGradient>
  <linearGradient id="gradient-royal" gradientUnits="userSpaceOnUse">
      <stop stop-color="#3D67FF" />
      <stop stop-color="#022E87" offset="100%" />
  </linearGradient>
</defs>`

// if SSR is enabled, content should be static and synced on the client hydration
const clientWithSSRInnerHTML = typeof document !== 'undefined' && document.getElementById(containerId)?.innerHTML

const SvgSprite = memo<{ withSSR?: boolean }>(({ withSSR = true }) => {
  const isClientWithSSRFlow = withSSR && typeof window !== 'undefined'

  let innerHtml = (isClientWithSSRFlow ? clientWithSSRInnerHTML : baseInnerHtml) || baseInnerHtml

  if (!isClientWithSSRFlow && 'getSymbolsHtml' in svgRuntime) {
    innerHtml += svgRuntime.getSymbolsHtml()
  }

  return (
    <svg
      id={containerId}
      xmlns="http://www.w3.org/2000/svg"
      dangerouslySetInnerHTML={{ __html: innerHtml }}
    />
  )
})

SvgSprite.displayName = 'SvgSprite'

export default SvgSprite
